
html {
    font-size: 62.5%;
}

.login-container {
    width: 100vw;
    height: 100svh;
    display: flex;
}

.login-ui-container {
    width: 60%;
    height: 100%;
    background: rgb(3, 0, 31);
    padding: 2.4rem;
}

.logo-container {
    height: 30%;
}

.logo-container img {
    width: 18rem;
}

.classroom-logo {
    width: 8rem;
}

.login-content-container {
    width: 40%;
    height: 100%;
    background: white;
}

.texts-container {
    color: #ff66b2;
    font-size: 2rem;
    margin-top: 5rem;
    padding-right: 24rem;
}

.texts-container h1 {
    font-weight: bold;
    font-family: "BPG Nino Mtavruli", sans-serif;
}

.texts-container span {
    font-weight: normal;
    font-family: "BPG Nino Mtavruli", sans-serif;
}

.login-bottomside-container {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /*padding-bottom: 50px;*/
    gap: 1.3rem;
}

.login-bottomside-container h1 {
    font-size: 3rem;
    font-family: "Merriweather Sans", sans-serif;
}

.terms-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.terms-container p {
    font-size: 2rem;
    font-family: "BPG Nino Mtavruli", sans-serif;
    text-align: center;
}

.started-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 50%;
}

.started-container h2 {
    font-size: 3.2rem;
    font-family: "Inter", serif;
}

.sign-in-btn {
    display: block;
    padding: 1.5rem 8rem;
    border: none;
    border-radius: 8px;
    background-color: #fe017e;
    color: white;
    font-family: "Inter", serif;
    transition: 0.3s ease-in-out;
}

.sign-in-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

.sign-in-btn:active {
    transform: scaleX(0.9);
}

.started-content {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;
}

@keyframes anim-show-text {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes anim-hide-text {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20%);
        visibility: hidden;
    }
}

@media only screen and (max-width: 1024px) {
    .login-ui-container {
        display: none;
    }

    .login-content-container {
        width: 100%;
    }
}


@media only screen and (max-width: 320px) {
    .sign-in-btn {
        padding: 1.5rem 5rem;
        text-wrap: nowrap;
    }
}
