
.classroom-loader-container {
    width: 100vw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.classroom-loader-container h2 {
    font-family: "Inter", serif;
    font-weight: normal;
    font-size: 1.6rem;
    margin-bottom: 24px;
    color: #9b9b9b;
}

.logo-loader img {
    max-width: 10rem;
}

/*.classroom-loader-container h3 {*/
/*    margin-top: auto;*/
/*}*/

.logo-loader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border-radius: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 12px 0 #fe017e;
}

.logo-loader span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
}

.logo-loader::after {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    border: 8px solid #fe017e;
    border-radius: 100%;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

.logo-loader::before {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    border: 8px solid #fe017e;
    border-radius: 100%;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
}

@-webkit-keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}


@media only screen and (max-width: 370px) {
    .logo h1 {
        font-size: 3.8rem;
    }
}
