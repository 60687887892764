.report-popup {
  background: #ffffff;
  border-radius: 1.3rem;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: 50%;
  margin-left: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-close {
  position: absolute;
  right: 1%;
  top: 2%;
  font-size: 2.2rem;
}

.report-close:hover {
  cursor: pointer;
}

.hide-container {
  display: none;
}

@media screen and (max-width: 1024px),
       screen and (max-height: 700px) {
  .report-popup {
    width: 100%;
    height: 100svh;
    margin-left: 0;
    border-radius: 0;
    padding: 3rem 0;
  }

  .report-close {
    right: 2%;
    top: 1.5%;
  }

  .report-container button.submit {
    width: 100%;
  }
}

